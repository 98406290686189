import { Navbar } from 'flowbite-react'
import React from 'react'
import { logOut } from '../redux/authSlice'
import { useDispatch, useSelector } from 'react-redux'


function Nav() {
    const dispatch = useDispatch()

    const isAuth = useSelector(state => state.auth.isAuth)

    const logOutClick = () => {
        localStorage.clear('token')
        dispatch(logOut())
    }

    const pathname = window.location.pathname
    console.log(pathname)
    return (
        <Navbar
            fluid={true}
            rounded={true}
            className='md:pr-20 border-b-2 border-gray-100'
        >
            <Navbar.Brand href="https://firstprice.nemo-monitoring.kz/">
                <img
                    src={require('../assets/img/logo.png')}
                    className="mr-3 h-6 sm:h-9"
                    alt="Nemo Logo"
                />
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white text-gray-700">
                    Superstar DashBoard
                </span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Navbar.Link
                    href="/"
                    active={pathname === '/' ? true : false}
                    className='text-base !bg-white'
                >
                   <p className={`${pathname === '/' ? 'text-blue-600' : '' }`}>Главная</p>
                </Navbar.Link>

                <Navbar.Link
                    href="https://app.nemo-monitoring.kz/"
                    className='text-base !bg-white'
                >
                    <p className={`${pathname === '/firstprice' ? 'text-blue-600' : '' }`} >Nemo DashBoard</p>
                </Navbar.Link>


                <Navbar.Link onClick={logOutClick}
                    className='text-base cursor-pointer'>
                    {isAuth === 'authed' && 'Выйти'}
                </Navbar.Link>
            </Navbar.Collapse>
        </Navbar>

    )
}

export default Nav