import React from 'react'
import ProductsTable from '../components/FPProductsTable'
import { useDispatch, useSelector } from 'react-redux'
import Toasts from '../components/Toasts'
import TableConfig from '../components/TableConfig'
import SpeedDeal from '../components/SpeedDeal'
import { Breadcrumb } from 'flowbite-react'
import { HiOutlineHome } from "react-icons/hi";
import { Navigate } from 'react-router-dom'
import { checkAuthorization } from '../services/AuthService'
import { useEffect, useState } from 'react'
import ParsingToolTip from '../components/ParsingToolTip'
import { checkParsing } from '../services/ProductAPI'


function HomePage() {
  const showToast = useSelector(state => state.toast.show)
  const queryParameters = new URLSearchParams(window.location.search)
  const productId = queryParameters.get("productId")
  const isAuth = useSelector(state => state.auth.isAuth)
  const dispatch = useDispatch()

  const [isParsing, setIsParsing] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      checkParsing()
        .then(response => {
          if (response.isParsing === true) {
            setIsParsing(true);
          } else {
            setIsParsing(false);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }, 9000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    dispatch(
      checkAuthorization()
    )

  }, [dispatch, checkAuthorization])

  if (isAuth === 'notAuth') {
    return <Navigate to='/login' />
  }
  return (
    <div>
      {isParsing &&  <ParsingToolTip />}
      {showToast && <Toasts />}
      <Breadcrumb aria-label="Superstar breadcrumb" className='px-4 pt-8'>
        <Breadcrumb.Item
          href="#"
          icon={HiOutlineHome}
        >
          Главная
        </Breadcrumb.Item>
        <Breadcrumb.Item href="#">
          Товары
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Kaspi
        </Breadcrumb.Item>
      </Breadcrumb>
     
      <TableConfig />
      <ProductsTable productId={productId} />
      <SpeedDeal />
    </div>

  )
}

export default HomePage